<template>
    <comp-form :structure="structure" :uuid="$route.query.id" submit-api="WEB://Employee/Save" detail-api="WEB://Employee/FindByUuid"></comp-form>
</template>

<script>
import CompForm from "@/components/comp-form.vue"

export default {
    components: {
        CompForm,
    },

    mounted() {
        console.log(this.$route)
    },

    data() {
        return {
            structure: [
                {
                    type: "ORDINARY",
                    name: "common",
                    data: [
                        {
                            field: "username",
                            name: "username",
                            title: "用户名",
                            type: "Input",
                            required: false,
                        },
                        {
                            field: "phone",
                            name: "phone",
                            title: "手机号码",
                            required: true,
                            type: "Input",
                        },
                        {
                            field: "enable",
                            name: "enable",
                            title: "是否启用",
                            type: "Switch",
                        },
                    ],
                    title: "通用数据",
                    list: false,
                    exist: true,
                },
                {
                    type: "PASSWORD",
                    name: "password",
                    data: [
                        {
                            field: "password",
                            name: "password",
                            title: "密码",
                            type: "Password",
                            required: false,
                        },
                    ],
                    title: "设置密码",
                    list: false,
                    exist: true,
                },
            ],
        }
    },
}
</script>
